import * as actionTypes from '../constants/sharedActionTypes';

import { updateObject } from '../../utils/utility';

const initialState = { userRole: null };

const saveUserRoleStart = (state, action) => state;
const saveUserRoleSuccess = (state, action) => { return updateObject(state, { userRole: action.userRole }); }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_USER_ROLE_START: return saveUserRoleStart(state, action);
    case actionTypes.SAVE_USER_ROLE_SUCCESS: return saveUserRoleSuccess(state, action);

    default: return state;
  }
}

export default reducer;