import React from "react";
import styled from "styled-components";
import { Table, Icon } from "semantic-ui-react";

import { REACT_APP_CENTER_REDIRECT_URL } from "shared/constants/envConstants";
import * as logTypes from "shared/constants/logTypes";
import * as logPlatform from "shared/constants/logPlatform";

const MessageCell = (props) => {
  const { open, item, onOpenClick } = props;
  const isMobile =
    item.platform === logPlatform.IOS || item.platform === logPlatform.ANDROID;
  const isMobileCrash = item.logType === logTypes.CRASH && isMobile;
  const isMobileError = item.logType === logTypes.ERROR && isMobile;

  const handleOpenRowClick = () => {
    onOpenClick();
  };

  if (isMobileCrash) {
    let redirectUrl = REACT_APP_CENTER_REDIRECT_URL;
    redirectUrl = redirectUrl.replace(
      "{projectName}",
      item.platform === logPlatform.IOS ? "IKE" : "IKE-1"
    );
    redirectUrl = redirectUrl.replace("{groupId}", item.groupId);
    redirectUrl = redirectUrl.replace("{id}", item.id);

    return (
      <Table.Cell>
        <span>
          <Icon
            link
            name="info circle"
            onClick={handleOpenRowClick}
            size="large"
            color="blue"
            rotated={open ? "clockwise" : null}
            title="Show details"
          />
          <RedirectLink href={redirectUrl} target="_blank" rel="noreferrer">
            {item.description}
          </RedirectLink>
        </span>
      </Table.Cell>
    );
  }

  if (isMobileError) {
    return (
      <Table.Cell>
        <Icon
          link
          name="info circle"
          onClick={handleOpenRowClick}
          size="large"
          color="blue"
          rotated={open ? "clockwise" : null}
          title="Show details"
        />
        <span>{item.description}</span>
      </Table.Cell>
    );
  }

  if (item.platform === logPlatform.API) {
    return (
      <Table.Cell>
        <Icon
          link
          name="info circle"
          onClick={handleOpenRowClick}
          size="large"
          color="blue"
          rotated={open ? "clockwise" : null}
          title="Show details"
        />
        <span>{item.description}</span>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <span>{item.description}</span>
      {item.error && <ErrorStyled>{`Error: ${item.error}`}</ErrorStyled>}
    </Table.Cell>
  );
};

const ErrorStyled = styled.p`
  font-weight: 600;
  color: red;
`;

const RedirectLink = styled.a`
  font-weight: 600;
  cursor: pointer;
`;

export { MessageCell };
