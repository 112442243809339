import * as constants from '../constants/validationErrorMessages';
import { states } from '../constants/listsConstants';
import { isArray, isNullOrUndefined } from 'util';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
}

export const validate = (form) => {
  for (let el in form) {
    let validationResult;

    if (form[el].validation) {
      validationResult = checkValidity(form[el].value, form[el].validation);

      form[el].valid.isValid = validationResult.isValid;
      form[el].valid.errorMessage = validationResult.errorMessage;
      form[el].touched = true;
    } else {
      form[el].valid.isValid = true;
      form[el].valid.errorMessage = null;
      form[el].touched = true;
    }
  }

  return form;
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const checkValidity = (value, rules) => {
  let isValid = true;
  let validationResult;

  if (!rules) {
    return {
      isValid: true,
      errorMessage: null
    };
  }

  if (rules.required) {
    if (isArray(value) && value.length) {
      isValid = true;
    } else {
      isValid = ((value !== null && value !== undefined && (value.toString().trim() !== '' || value.toString().trim() !== "")) && value !== "none") && isValid;
    }

    validationResult = isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: constants.REQUIRED_ERROR_MESSAGE
      };


  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\b/;
    isValid = pattern.test(value) && isValid;

    validationResult = isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: constants.IS_EMAIL_ERROR_MESSAGE
      };
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;

    validationResult = isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: `${constants.MIN_LENGTH_ERROR_MESSAGE} ${value.length}`
      };
  }

  if (rules.requiredLength) {
    isValid = value.toString().length === rules.requiredLength && isValid;

    validationResult = isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: `${constants.REQUIRED_LENGTH_ERROR_MESSAGE} ${rules.requiredLength}`
      };
  }

  if (rules.minAddressLength) {
    isValid = value.length >= rules.minAddressLength && isValid;

    validationResult = isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: `${constants.ADDRESS_ERROR_MESSAGE} ${rules.minAddressLength}`
      };
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;

    validationResult = isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: constants.MAX_LENGTH_ERROR_MESSAGE
      };
  }

  if (rules.maxNumberValue) {
    isValid = value <= rules.maxNumberValue && isValid;

    validationResult = isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: `${constants.MAX_VALUE_ERROR_MESSAGE} (${rules.maxNumberValue})`
      };
  }

  if (rules.minNumberValue) {
    isValid = value >= rules.minNumberValue && isValid;

    validationResult = isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: `${constants.MIN_VALUE_ERROR_MESSAGE} (${rules.minNumberValue})`
      };
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;

    validationResult = isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: constants.IS_NUMERIC_ERROR_MESSAGE
      };
  }

  if (rules.isFloat) {
    const pattern = /^[+-]?\d+(\.\d+)?$/;
    isValid = pattern.test(value) && isValid;

    validationResult = isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: constants.IS_FLOAT_ERROR_MESSAGE
      };
  }

  if (!isValid) {
    return validationResult;
  }
  else return {
    isValid: true,
    errorMessage: null
  }
}

export const formatActionAddress = (address) => {
  const getPropertyValueOrEmptyString = (propVal) => {
    return propVal ? propVal : '';
  }

  return `${getPropertyValueOrEmptyString(address.street).toLowerCase()} `
    + `${getPropertyValueOrEmptyString(address.city).toLowerCase()},`
    + ` ${getPropertyValueOrEmptyString(address.zip)}, ${getPropertyValueOrEmptyString(address.state)}, ${getPropertyValueOrEmptyString(address.country)}`;
}

export const formatPhoneNumber = (phoneNumberString) => {
  return phoneNumberString;

  // todo: fix logic below to allow phone numbers not only from US
  // const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  // const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  // if (match) {
  //   // const intlCode = (match[1] ? '+1 ' : '');
  //   return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  // }

  // return null;
}

export const remove = (arr, value) => {
  let i = arr.indexOf(value);
  if (i !== -1) arr.splice(i, 1);

  return arr;
}

export const normalize = (value) => {
  if (value) {
    if (states.includes(value)) {
      return value;
    }

    let normalizedText;

    let index = value.indexOf(',');
    if (index !== -1) {
      normalizedText = `${value.substring(0, index).replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())}${value.substring(index, value.length)}`;
    } else {
      normalizedText = `${value.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())}`;
    }

    let slashIndex = normalizedText.indexOf('/');
    if (slashIndex !== -1) {
      let resultText = `${normalizedText.charAt(slashIndex - 1).toUpperCase()}${normalizedText.charAt(slashIndex)}${normalizedText.charAt(slashIndex + 1).toUpperCase()}`;

      return normalizedText.substr(0, slashIndex - 1) + resultText + normalizedText.substr(slashIndex + resultText.length - 1);
    }

    let ampIndex = normalizedText.indexOf('&');
    if (ampIndex !== -1) {
      let resultText = `${normalizedText.charAt(ampIndex - 1).toUpperCase()}${normalizedText.charAt(ampIndex)}${normalizedText.charAt(ampIndex + 1).toUpperCase()}`;

      return normalizedText.substr(0, ampIndex - 1) + resultText + normalizedText.substr(ampIndex + resultText.length - 1);
    }

    return normalizedText;
  } else {
    return 'n/a';
  }
};

export const formatNumberWithCommas = (x) => {
  if (x) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  } else {
    return 0;
  }
}

export const parseQuery = (queryString) => {
  let query = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  return query;
}

export const generateCountryOptions = (countryCodes) => {
  let options = [];

  options = countryCodes.map(opt => {
    return {
      key: opt.twoLetterCode,
      text: `${opt.countryName} (+${opt.phoneCountryCode})`,
      value: `+${opt.phoneCountryCode}`
    }
  })

  return options;
}

export const phoneNumberConverter = (phoneNumber) => {
  if (isNullOrUndefined(phoneNumber)) return phoneNumber;

  let formatedPhoneNumber = '';
  if (phoneNumber.length === 9) {
    let numbers = phoneNumber.replace(/\D/g, ''), char = { 0: '(', 2: ') ', 5: '-' };

    for (let i = 0; i < numbers.length; i++) {
      formatedPhoneNumber += (char[i] || '') + numbers[i];
    }
  } else if (phoneNumber.length === 10) {
    let numbers = phoneNumber.replace(/\D/g, ''), char = { 0: '(', 3: ') ', 6: '-' };
    for (let i = 0; i < numbers.length; i++) {
      formatedPhoneNumber += (char[i] || '') + numbers[i];
    }
  } else {
    let numbers = phoneNumber.replace(/\D/g, ''), char = { 2: '-', 6: '-' };
    for (let i = 0; i < numbers.length; i++) {
      formatedPhoneNumber += (char[i] || '') + numbers[i];
    }
  }

  return formatedPhoneNumber;
}

export const reportTypeFormatter = (type) => {
  switch (type) {
    case 1:
      return 'Inspection Report';
    case 2:
      return 'Proof Of Delivery';
    default:
      break;
  }
}