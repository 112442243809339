import React from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { Dimmer, Loader, Segment, Header, Icon, Input, Dropdown } from 'semantic-ui-react';

import Paginator from '../../components/Paginator';
import GridBase from '../../components/GridBase';

import Signature from './Signature';

import { formatNumberWithCommas } from '../../shared/utils/utility';
import * as actions from '../../shared/store/actions';

import styles from './ShipmentBoard.module.scss';

const options = [
  { value: 10, text: '10' },
  { value: 20, text: '20' },
  { value: 50, text: '50' },
  { value: 100, text: '100' }
];

class ShipmentBoard extends GridBase {
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.shipments && this.props.shipments.data && this.props.shipments.data.data && !isEqual(this.props.shipments, prevProps.shipments)) {
      this.setState({ shipments: this.props.shipments.data });
    }
  }

  loadData = () => {
    let updSearch = '';
    const { search, page, pageSize } = this.state;

    updSearch = search.startsWith('#')
      ? search.substr(1)
      : search;

    let filters = {
      search: updSearch,
      page,
      pageSize
    };

    this.props.fetchShipmentBoardData(filters);
  }

  handleRefresh = () => this.loadData();

  render() {
    const { shipments, search } = this.state;
    const { shipmentsLoading } = this.props;

    let content = <></>;

    if (shipmentsLoading) {
      content = <>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </>;
    } else if (!shipmentsLoading && (!shipments || !shipments.data.length)) {
      content = <p className={styles.noData}>No data to display</p>;
    } else {
      content = shipments && shipments.data
        ? shipments.data.map(shipment => {
          return <Segment color='blue' key={shipment.shipmentId}>
            <Header as='h5' dividing>Shipment #{shipment.customId}</Header>
            <div className={styles.ShipmentItem}>
              {shipment.turvoRoutes
                ? shipment.turvoRoutes.map((routeItem, index) => {
                  return <div className={styles.RouteItem} key={routeItem.id}>
                    <div className={styles.routeItemContent}>
                      <div>
                        <div>
                          {routeItem.location ? routeItem.location.name : null}
                        </div>
                        <div>
                          {routeItem.address
                            ? <>{routeItem.address.line1}, {routeItem.address.city}, {routeItem.address.state}, {routeItem.address.zip}</>
                            : null}
                        </div>
                      </div>

                      {shipment.turvoRoutes && shipment.turvoRoutes.length && index !== shipment.turvoRoutes.length - 1
                        ? <Icon name='arrow right' size='big' color='grey' style={{ marginLeft: '10px', marginRight: '10px' }} />
                        : null}
                    </div>
                    {routeItem.signatures && routeItem.signatures.length
                      ? <Signature signatures={routeItem.signatures} />
                      : null}
                  </div>;
                })
                : null}
            </div>
          </Segment>;
        })
        : null;
    }

    let showValidationMessage = false;

    if (search !== '') {
      if (search.startsWith('#') && search.length < 6) {
        showValidationMessage = true;
      } else if (search.length < 5) {
        showValidationMessage = true;
      }
    }

    return <div className={styles.ShipmentBoard}>
      <div className={styles.ShipmentBoardHeader}>
        <span className={styles.headerText}>Shipments
        <Icon name='refresh' onClick={this.handleRefresh} size='small' style={{ marginLeft: '5px', cursor: 'pointer' }} />
        </span>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Input icon='search' placeholder='Search by custom Id' value={search} onChange={(e, { value }) => this.handleFilter(e, { value })} />
          {showValidationMessage ? <p style={{ fontSize: '11px', color: 'red' }}>Minimum 5 numbers required</p> : null}
        </div>
      </div>
      <div className={styles.ShipmentsContainer}>
        {content}
      </div>

      {shipments && shipments.data
        ? <div className={styles.pagingControlContainer}>
          <span>Showing{' '}
            <Dropdown inline upward options={options}
              defaultValue={this.state.pageSize}
              onChange={this.handlePageSizeChange} />{' '}of {formatNumberWithCommas(shipments.totalCount)} Results</span>
          {shipments.totalCount
            ? <Paginator secondary pointing pageSizeStatic
              pageChanged={this.handlePageChange}
              filteredCount={shipments.totalCount}
              pageSize={this.state.pageSize}
              page={this.state.page} />
            : null}
        </div>
        : null}
    </div>;
  }
}

const mapStateToProps = state => {
  return {
    shipments: state.board.shipments,
    shipmentsLoading: state.board.shipmentsLoading,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchShipmentBoardData: (filters) => dispatch(actions.fetchShipmentBoardData(filters))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentBoard);