import React from 'react';

import { Pagination, Dropdown } from 'semantic-ui-react';

import styles from './Paginator.module.scss';

const Paginator = ({
  pageSizeChanged,
  pageChanged,
  pageSizeStatic,
  filteredCount,
  customStyles,
  pageSize,
  secondary,
  pointing,
  size,
  page,
}) => {
  const totalPages = Math.ceil(filteredCount / pageSize);

  const options = [
    { value: 10, text: '10' },
    { value: 20, text: '20' },
    { value: 50, text: '50' },
    { value: 100, text: '100' }
  ];

  return (
    <div className={styles.TableActions} style={customStyles}>
      {pageSizeStatic ? null
        : <Dropdown
          upward
          selection
          className={styles.PageSizeSelect}
          defaultValue={pageSize}
          options={options}
          onChange={pageSizeChanged} />}
      <Pagination
        pointing={pointing}
        secondary={secondary}
        size={size}
        activePage={page}
        totalPages={totalPages}
        onPageChange={pageChanged} />
    </div>
  );
}

export default Paginator;
