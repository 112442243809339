import React from "react";
import { Table } from "semantic-ui-react";

const LogTableEmptyRow = () => (
  <Table.Row>
    <Table.Cell textAlign="center" colSpan={4}>
      No logs
    </Table.Cell>
  </Table.Row>
);

export { LogTableEmptyRow };
