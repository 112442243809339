import { WebStorageStateStore } from 'oidc-client';
import { REACT_APP_AUTH_CLIENT_ID } from 'shared/constants/envConstants';

export const AUTH_CONFIG = {
  client_id: REACT_APP_AUTH_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
  response_type: 'token id_token',
  scope: 'openid profile',
  authority: 'https://auth.loadrpm.com',
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: localStorage })
};