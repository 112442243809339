import axios from '../../../../axios/axios-api';

import * as actionTypes from '../constants/actionTypes';

const fetchShipmentBoardDataStart = () => { return { type: actionTypes.FETCH_SHIPMENT_BOARD_DATA_START }; }
const fetchShipmentBoardDataSuccess = (shipments) => { return { type: actionTypes.FETCH_SHIPMENT_BOARD_DATA_SUCCESS, shipments: shipments }; }
const fetchShipmentBoardDataError = () => { return { type: actionTypes.FETCH_SHIPMENT_BOARD_DATA_ERROR }; }

export const fetchShipmentBoardData = (filters) => {
  return dispatch => {
    dispatch(fetchShipmentBoardDataStart());

    let skip = filters.page === 1 ? 0 : (filters.page - 1) * filters.pageSize;
    axios.get(`admin/shipment/shipments-with-signature?skip=${skip}&take=${filters.pageSize}&pageSize=${filters.pageSize}&customId=${filters.search}`)
      .then((res) => dispatch(fetchShipmentBoardDataSuccess(res)))
      .catch(() => dispatch(fetchShipmentBoardDataError()));
  }
}

const regenerateInspectionReportStart = () => { return { type: actionTypes.REGENERATE_INSPECTION_REPORT_START }; }
const regenerateInspectionReportSuccess = (res) => { return { type: actionTypes.REGENERATE_INSPECTION_REPORT_SUCCESS, response: res }; }
const regenerateInspectionReportError = () => { return { type: actionTypes.REGENERATE_INSPECTION_REPORT_ERROR }; }

export const regenerateInspectionReport = (signatureId) => {
  return dispatch => {
    dispatch(regenerateInspectionReportStart());
    
    return new Promise((resolve, reject) => {
      axios.post(`driver/report/regenerate-inspection-report/${signatureId}`)
        .then((res) => {
          dispatch(regenerateInspectionReportSuccess(res));
          resolve(res);
        })
        .catch((error) => {
          dispatch(regenerateInspectionReportError());
          reject(error);
        });
    });
  }
}