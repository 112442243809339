import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Table, Icon, Input, Header, Button, Dimmer, Loader } from 'semantic-ui-react';

import Paginator from '../Paginator';

import styles from './ResourceViewer.module.scss';

let timeout = null;

export default class ResourceViewer extends Component {
  state = this.getInitialState();

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data ? this.props.data : [] });
    }

    if (prevProps.showNewItemRow !== this.props.showNewItemRow) {
      this.setState({ newRow: {} });
    }
  }

  getInitialState() {
    return {
      newRow: {},
      data: this.props.data || [],
      exportFormat: 'CSV',
      showModal: false,
      pageSize: 10,
      search: '',
      activeIndexes: []
    };
  }

  getNewRow = () => {
    let newRow = {};
    this.props.columns.forEach((x) => newRow[x.id] = "");
    return newRow;
  }

  renderEditColumn = (col, item) => {
    let colId = col.id;

    if (col.type === 'number') {
      return <Table.Cell key={col.id}>
        <Input
          style={{ width: '100%' }}
          type="number"
          value={item[colId]}
          onChange={(e) => {
            item[colId] = e.target.value;
            this.forceUpdate();
          }}
        />
      </Table.Cell>;
    } else {
      return <Table.Cell key={col.id}>
        <Input
          style={{ width: '100%' }}
          type='text'
          value={item[colId]}
          onChange={(e) => {
            item[colId] = e.target.value;
            this.forceUpdate();
          }}
        />
      </Table.Cell>;
    }
  };

  renderColumn = (col, item) => {
    if (col.isHidden) {
      return null;
    }
    if (item.isEditMode && this.props.editMode) {
      return this.renderEditColumn(col, item);
    } else {
      return <Table.Cell className={styles.cellContent} key={col.id} title={col.id === 'customerName' ? item[col.id] : null}>
        <div className={[styles.cell, col.cellStyle].join(' ')}
          style={col.id === 'customerName' && item[col.id] && item[col.id].length >= 20 ? { width: '150px' } : null}
          onClick={(e) => {
            if (col.allowEventPropagation)
              return;

            e.stopPropagation();
            e.preventDefault();
          }}>{col.accessor ? col.accessor(item) : item[col.id]}</div>
      </Table.Cell>;
    }
  };

  renderHeaderRow = () => {
    return (
      <Table.Header>
        {this.props.displaySearch ? <Table.Row className={this.props.displaySearch ? styles.FilterRow : styles.FilterRowWithoutActions}>
          <Table.HeaderCell
            colSpan={this.props.columns.length}>
            <Input
              className={styles.SearchInput}
              placeholder='Filter...'
              icon='search'
              value={this.props.search ? this.props.search : ''}
              onChange={(event) => this.props.filterGrid(event.target.value)} />

          </Table.HeaderCell>
          {this.props.editMode
            ? <Table.HeaderCell width='2'></Table.HeaderCell>
            : null}
        </Table.Row> : null}

        <Table.Row className={this.props.displaySearch ? styles.HeaderRow : styles.HeaderRowWithoutActions}>
          {this.props.columns.map(column => {
            return (
              <Table.HeaderCell
                key={column.id}
                width={column.width}
                style={{ cursor: column.sorting ? 'pointer' : 'default' }}
                sorted={column.sorting ? this.manageSorting(column.id) : null}
                onClick={column.sorting ? () => this.props.sorting(column.id) : null}>
                {column.header}
              </Table.HeaderCell>
            )
          })}
          {this.props.editMode
            ? <Table.HeaderCell></Table.HeaderCell>
            : null}
        </Table.Row>
      </Table.Header>
    )
  };

  manageSorting = (columnId) => {
    const matches = this.props.sort.filter(s => s.includes(columnId));

    if (matches.length > 0) {
      let direction = matches[0].split(':').pop();
      return direction === 'asc' ? 'ascending' : 'descending';
    } else {
      return null;
    }
  }

  tableLoader = () => <Dimmer inverted active className={styles.Dimmer}>
    <Loader size='medium' />
  </Dimmer>;

  renderNewItemRow = () => {
    let columns = this.props.columns.map((col) => {
      if (col.isHidden)
        return null;

      let newRow = this.state.newRow;

      return <Table.Cell key={col.id}>
        <Input type="text"
          style={{ width: '100%' }}
          onChange={(e) => {
            if (Object.keys(newRow).length === 0)
              newRow = this.getNewRow();

            newRow[col.id] = e.target.value;
            this.setState({ newRow: newRow });
          }} />
      </Table.Cell>;
    });

    if (this.props.editMode)
      columns.push(<Table.Cell key="action">
        <Icon name='check' onClick={(e) => this.props.handleChange(this.state.newRow)} style={{ cursor: 'pointer' }} />
        <Icon name='close' onClick={(e) => {
          this.props.handleCancelAdd();
          this.setState({ newRow: {} })
        }} style={{ cursor: 'pointer' }} />
      </Table.Cell>);

    if (this.props.selectionMode)
      columns.unshift(<Table.Cell key="selectionCol">
        <div>
          <Input type="checkbox" value={this.state.newRow.checked} />
        </div>
      </Table.Cell>);

    return <Table.Row>{columns}</Table.Row>;
  };

  renderRow = (item, i) => {
    let rows = [];

    let itemRow = <Table.Row className={styles.tableRow} key={item.id ? item.id : i}>
      {this.props.selectionMode
        ? <Table.Cell key={item.id ? item.id : i}>
          <div>
            <Input onChange={() => this.props.onItemCheck(item)} type="checkbox" checked={item.checked} />
          </div>
        </Table.Cell>
        : null}

      {this.props.columns.map((col) => this.renderColumn(col, item))}

      {this.props.editMode
        ? (item.isEditMode
          ? <Table.Cell>
            <Icon name='check'
              onClick={(e) => {
                item.isEditMode = false;
                this.forceUpdate();
                let { isEditMode, ...itemModel } = item;
                this.props.handleChange(itemModel);
              }} />
            <Icon name='close'
              onClick={(e) => {
                item.isEditMode = false;
                this.forceUpdate();
              }}
              style={{ cursor: 'pointer' }} />
          </Table.Cell>
          : <Table.Cell>
            <Icon name='edit'
              onClick={(e) => {
                item.isEditMode = true;
                this.forceUpdate();
              }}
              style={{ cursor: 'pointer' }} />
            <Icon name='close'
              onClick={(e) => this.props.handleRemove(item.id)}
              style={{ cursor: 'pointer' }} />
          </Table.Cell>)
        : null}
    </Table.Row>;

    rows.push(itemRow);

    return rows;
  };

  componentWillUnmount() {
    clearTimeout(timeout);
  };

  render() {
    const tableClassNames = [styles.Table];

    if (this.props.tableClassName) {
      tableClassNames.push(this.props.tableClassName);
    }

    let table = (
      <div className={styles.Container}>
        {this.props.actions
          ? <div className={styles.TableHeaderActions}>
            <span className={styles.TableHeader}>
              <Header as='h3' className={styles.headerText}>{this.props.header}</Header>
              <Icon name='refresh' onClick={this.props.refreshTable} className={styles.refreshBtn} />
            </span>

            <Button className={styles.TableActionsButton} icon size='small' floated='right' labelPosition='left'
              as={Link} to={this.props.createResourceLink} exact="true">
              <Icon name='add' />
              Create
            </Button>

            {this.props.editMode
              ? <Button type='button' className={styles.TableActionsButton}
                icon size='small' floated='right' labelPosition='left'
                onClick={this.props.handleAddNewRow} >
                <Icon name='add' />
                Add
            </Button>
              : null}
          </div>
          : null}

        <Table sortable className={tableClassNames.join(' ')}>
          {this.renderHeaderRow()}

          {this.props.expandable
            ? this.props.renderExpandableTable(
              this.renderColumn,
              this.props.renderContentInfo,
              this.props.columns,
              this.props.accordionLoading,
              this.state.data,
              this.props.manageExpanded,
              this.props.releaseRow,
              this.props.reload)
            : <Table.Body>
              {this.props.loading
                ? <Table.Row className={styles.tableRow}>
                  <Table.Cell className={styles.tableLoaderCell} colSpan={this.props.columns.length}>
                    {this.tableLoader()}
                  </Table.Cell>
                </Table.Row>
                : null}

              {this.props.showNewItemRow && this.renderNewItemRow()}

              {!this.props.loading && this.state.data && this.state.data.length > 0
                ? this.state.data.map((item, i) => this.renderRow(item, i))
                : !this.props.loading
                  ? <Table.Row className={styles.tableRow}>
                    <Table.Cell colSpan={this.props.columns.length} textAlign='center'>
                      <p className={styles.noData}>No data to display</p>
                    </Table.Cell>
                  </Table.Row>
                  : null}
            </Table.Body>}

          {this.props.renderCustomFooter
            ? this.props.renderCustomFooter(
              this.props.columns,
              this.props.totalCount,
              this.props.pageIndex,
              this.props.pageSize,
              this.props.pageChanged,
              this.props.shipment)
            : null}

          {this.props.pagination ? this.state.data && this.state.data && this.state.data.length
            ? <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={this.props.columns.length} className={styles.footerRow}>
                  <div className={styles.footerActions}>
                    {this.props.renderTotal ? this.props.renderTotal(this.props.totalCount) : null}
                    <Paginator
                      floated='right'
                      size='small'
                      pageSizeStatic={this.props.pageSizeStatic}
                      filteredCount={this.props.totalCount}
                      page={this.props.pageIndex}
                      pageSize={this.props.pageSize}
                      pageSizeChanged={this.props.pageSizeChanged}
                      pageChanged={this.props.pageChanged} />
                    {this.props.footerActions ? this.props.renderFooterActions() : null}
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer> : null : null}
        </Table>
      </div>
    );

    return table;
  }
}