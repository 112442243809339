import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Layout from "hoc/Layout/Layout";
import withErrorHandler from "hoc/withErrorHandler/withErrorHandler";

import ShipmentBoard from "pages/ShipmentBoard";
import LogList from 'pages/LogList';

import userRoleUtil from "./rbac/userRolesUtil";

import * as actions from "shared/store/actions";

import axios from "./axios/axios-api";

class App extends Component {
  state = { userRole: null, isReloaded: false, isLoaded: false };

  componentDidMount() {
    if (this.props.user) {
      this.setState(
        {
          userRole: userRoleUtil(this.props.user.profile.roles),
          isLoaded: true,
        },
        () => this.props.saveUserRole(this.state.userRole)
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user !== this.props.user &&
      this.props.user &&
      this.props.user.profile &&
      !this.state.isLoaded
    ) {
      this.setState(
        { userRole: userRoleUtil(this.props.user.profile.roles) },
        () => this.props.saveUserRole(this.state.userRole)
      );
    }

    if (
      prevProps.user &&
      prevProps.user.profile &&
      prevProps.user.profile.roles &&
      this.props.user &&
      this.props.user.profile.roles &&
      this.props.user.profile.roles !== prevProps.user.profile.roles &&
      !this.state.isReloaded
    ) {
      this.setState({ isReloaded: true }, () => {
        if (caches) {
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }

        window.location.reload(true);
      });
    }
  }

  render() {
    let content = (
      <Layout>
        <div>Loading...</div>
      </Layout>
    );

    if (this.props.user) {
      content = (
        <Layout user={this.props.user}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/shipments" />} />
            <Route path="/shipments" component={ShipmentBoard} />
            <Route path="/logs" component={LogList} />
          </Switch>
        </Layout>
      );
    }

    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    isLoadingUser: state.oidc.isLoadingUser,
    user: state.oidc.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserRole: (userRole) => dispatch(actions.saveUserRole(userRole)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(App, axios, true));
