import React from "react";
import { Label } from "semantic-ui-react";
import * as logTypes from 'shared/constants/logTypes'

const LogTypeLabel = ({ type }) => {
  switch (type) {
    case logTypes.SUCCESS:
      return <Label color="green">Success</Label>;
    case logTypes.ERROR:
      return <Label color="red">Error</Label>;
    case logTypes.CRASH:
      return <Label color="black">Crash</Label>;
    case logTypes.EVENT:
      return <Label color="teal">Event</Label>;
    default:
      return null;
  }
};

export { LogTypeLabel };
