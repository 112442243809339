import React from "react";
import { Icon } from "semantic-ui-react";
import * as logPlatform from 'shared/constants/logPlatform'

const PlatformIcon = ({ type }) => {
  switch (type) {
    case logPlatform.IOS:
      return <Icon name="apple" color="black" size="big" title="iOS" />;
    case logPlatform.ANDROID:
      return <Icon name="android" color="olive" size="big" title="Android" />;
    case logPlatform.API:
      return <Icon name="server" color="grey" size="big" title="API" />;
    default:
      return null;
  }
};

export { PlatformIcon };
