import React from "react";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";

const DATE_FORMAT = "MM/DD/YYYY";

const LogDatepicker = ({ value, onChange }) => {
  const handleTodayClick = () => {
    onChange(new Date());
  };

  const handleYesterdayClick = () => {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    onChange(date);
  };

  const handleDPSelect = (e, data) => {
    if (!data.value) {
      onChange(null);
      return;
    }
    onChange(data.value);
  };

  return (
    <DatepickerContainerStyled>
      <Button color="blue" onClick={handleTodayClick}>
        Today
      </Button>
      <Button color="blue" onClick={handleYesterdayClick}>
        Yesterday
      </Button>
      <SemanticDatepicker
        pointing="right"
        format={DATE_FORMAT}
        value={value}
        onChange={handleDPSelect}
        locale="en-US"
        showToday={false}
        maxDate={new Date()}
        datePickerOnly
      />
    </DatepickerContainerStyled>
  );
};

const DatepickerContainerStyled = styled.div`
  margin-left: auto;
`;

export { LogDatepicker };
