import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Icon, Form } from "semantic-ui-react";
import styled from "styled-components";
import { debounce } from "lodash";

import { LogDatepicker } from "./components/LogDatepicker";
import { LogTable } from "./components/LogTable";
import { actions } from "./redux/logSlice";

const DISABLE_ID = "searchId";
const DISABLE_PHONE = "searchPhone";

const getISOStringDateWithTZ = (date) =>
  date
    ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
    : null;

const LogList = (props) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(null);
  const [filter, setFilter] = useState({
    date: new Date(),
    searchId: null,
    phone: null,
  });
  const { loading, data } = useSelector((state) => state.logs);

  const handleDateChange = (date) => {
    setFilter({ ...filter, date: date });
  };

  const handleShipmentIdChange = (event) => {
    const { value } = event.target;
    setFilter({
      ...filter,
      searchId: value,
    });

    if (!value && !filter.phone) {
      setDisabled(null);
      return;
    }

    if (!value && filter.phone) {
      setDisabled(DISABLE_ID);
      return;
    }

    if (value && disabled !== DISABLE_PHONE) {
      setDisabled(DISABLE_PHONE);
      return;
    }
  };

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    setFilter({
      ...filter,
      phone: value,
    });

    if (!value && !filter.searchId) {
      setDisabled(null);
      return;
    }

    if (!value && filter.searchId) {
      setDisabled(DISABLE_PHONE);
      return;
    }

    if (value && disabled !== DISABLE_ID) {
      setDisabled(DISABLE_ID);
      return;
    }
  };

  const handleSwitchClick = () => {
    if (disabled === DISABLE_ID) {
      setDisabled(DISABLE_PHONE);
    } else if (disabled === DISABLE_PHONE) {
      setDisabled(DISABLE_ID);
    }
  };

  const fetchData = ({ phoneNumber, reportDate, searchId, disableStatus }) => {
    const formattedDate = getISOStringDateWithTZ(reportDate);
    if (disableStatus === DISABLE_ID && phoneNumber?.length >= 10) {
      dispatch(
        actions.fetchLog({
          reportDate: formattedDate,
          phoneNumber,
        })
      );
    } else if (disableStatus === DISABLE_PHONE && searchId?.length > 2) {
      const pattern = /[0-9a-zA-Z]{5}-[0-9a-zA-Z]{5}/g;
      if (pattern.test(filter.searchId)) {
        dispatch(
          actions.fetchLog({
            reportDate: formattedDate,
            customId: searchId,
          })
        );
      } else {
        dispatch(
          actions.fetchLog({
            reportDate: formattedDate,
            shipmentId: searchId,
          })
        );
      }
    }
  };

  const handleRefreshClick = () => {
    fetchData({
      phoneNumber: filter.phone,
      reportDate: filter.date,
      searchId: filter.searchId,
      disableStatus: disabled,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDebounced = useCallback(
    debounce(({ phoneNumber, reportDate, searchId, disableStatus }) => {
      fetchData({
        phoneNumber,
        reportDate,
        searchId,
        disableStatus,
      });
    }, 1000),
    []
  );

  useEffect(() => {
    if (disabled && filter.date && (filter.phone || filter.searchId)) {
      fetchDebounced({
        phoneNumber: filter.phone,
        reportDate: filter.date,
        searchId: filter.searchId,
        disableStatus: disabled,
      });
    }
  }, [filter.date, filter.searchId, filter.phone, fetchDebounced, disabled]);

  return (
    <Container>
      <HeaderStyled>
        <Form>
          <Form.Group style={{ margin: 0 }}>
            <Form.Field>
              <IconStyled
                name="refresh"
                size="large"
                link
                onClick={handleRefreshClick}
              />
            </Form.Field>
            <Form.Field disabled={disabled === DISABLE_ID}>
              <label>Shipment Id / Custom Id</label>
              <Input
                icon="search"
                placeholder="Any Id"
                onChange={handleShipmentIdChange}
                disabled={disabled === DISABLE_ID}
              />
            </Form.Field>
            <Form.Field>
              <IconStyled
                name="exchange"
                size="large"
                link
                onClick={handleSwitchClick}
              />
            </Form.Field>
            <Form.Field disabled={disabled === DISABLE_PHONE}>
              <label>Phone Number</label>
              <Input
                icon="search"
                placeholder="+99 (999) 999 - 9999"
                onChange={handlePhoneChange}
                disabled={disabled === DISABLE_PHONE}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        <LogDatepicker value={filter.date} onChange={handleDateChange} />
      </HeaderStyled>

      <LogTable data={data} loading={loading} />

      <FooterStyled>
        <span>Total: {data.length || 0}</span>
      </FooterStyled>
    </Container>
  );
};

export { LogList };

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderStyled = styled.div`
  background-color: #e8ebef;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
  padding: 11px 30px;
  height: 80px;
  z-index: 999;
`;

const IconStyled = styled(Icon)`
  margin-top: 30px !important;
`;

const FooterStyled = styled.div`
  background-color: #e8ebef;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  height: 42px;
  padding: 11px 30px;
`;
