import React, { useRef, useState } from "react";
import { Table, Dimmer, Loader } from "semantic-ui-react";
import styled from "styled-components";

import { LogTableRow } from "./LogTableRow";
import { LogTableEmptyRow } from "./LogTableEmptyRow";

const LogTable = ({ data, loading }) => {
  const scrollerRef = useRef(null);
  const [shadow, setShadow] = useState(false);
  const [openedItemId, setOpenedItemId] = useState(null);

  const handleTableScroll = () => {
    if (!scrollerRef?.current) {
      return;
    }

    if (!shadow && scrollerRef.current.scrollTop > 0) {
      setShadow(true);
    }

    if (shadow && scrollerRef.current.scrollTop === 0) {
      setShadow(false);
    }
  };

  return (
    <TableContainerStyled
      ref={scrollerRef}
      onScroll={() => {
        handleTableScroll();
      }}
    >
      <TableStyled celled selectable compact>
        <TableHeaderStyled shadow={shadow ? 1 : 0}>
          <Table.Row>
            <TableHeaderCellStyled width={2}>App</TableHeaderCellStyled>
            <TableHeaderCellStyled width={2}>Date</TableHeaderCellStyled>
            <TableHeaderCellStyled width={1}>Log Type</TableHeaderCellStyled>
            <TableHeaderCellStyled>Log</TableHeaderCellStyled>
          </Table.Row>
        </TableHeaderStyled>
        {!loading && (
          <Table.Body>
            {data.length === 0 && <LogTableEmptyRow />}
            {data.map((item, idx) => (
              <LogTableRow
                key={idx}
                tabIndex={idx === 0 ? 0 : -1}
                item={item}
                open={openedItemId === item.id}
                onOpenClick={() => setOpenedItemId(item.id)}
                onCloseClick={() => setOpenedItemId(null)}
              />
            ))}
          </Table.Body>
        )}
      </TableStyled>
      <Dimmer active={loading} inverted style={{ zIndex: 1 }}>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </TableContainerStyled>
  );
};

const TableHeaderStyled = styled(Table.Header)`
  position: sticky;
  top: 0;
  background-color: white;
  font-size: 1.1em;
  font-weight: 600;
  box-shadow: ${(props) =>
    props.shadow ? "0px 1px 10px 0px rgb(0 0 0 / 25%) !important" : "none"};
  clip-path: ${(props) => (props.shadow ? "inset(0px 0px -10px 0px)" : "none")};
  z-index: 1;
`;

const TableContainerStyled = styled.div`
  flex-grow: 1;
  overflow: overlay;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #e8ebef;
  position: relative;
`;

const TableStyled = styled(Table)`
  border-top: 0 !important;
  border-radius: 0 !important;
`;

const TableHeaderCellStyled = styled(Table.Cell)`
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
`;

export { LogTable };
