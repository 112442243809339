import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppNavHeaderWithPaths } from "rpm-app-navigation-header";

import { REACT_APP_NAVBAR_API } from "shared/constants/envConstants";
import { userManager } from "rug-widgets";

import axios from "axios/axios-api";

import styles from "./Layout.module.scss";

const Layout = (props) => {
  const [activeItem, setActiveItem] = useState("/shipments");
  const history = useHistory();
  const user = useSelector((state) => state.oidc.user);

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.startsWith("/shipments")) {
      setActiveItem("/shipments");
    } else if (pathname.startsWith("/logs")) {
      setActiveItem("/logs");
    }
  }, []);

  const handleLocalpathPush = (urlPath) => {
    history.push(urlPath);
    setActiveItem(urlPath);
  };

  const handleLogout = () => {
    function cleanLS() {
      const arrayToRemove = [];
      for (let i = 0; i < localStorage.length; i++) {
        const lsKey = localStorage.key(i);
        if (lsKey.includes("oidc.")) {
          arrayToRemove.push(lsKey);
        }
      }
      arrayToRemove.forEach((item) => {
        localStorage.removeItem(item);
      });
    }

    cleanLS();
    userManager.signoutRedirect().catch(function (error) {
      console.error("error while signing out user", error);
    });
  };

  const fetchPaths = () => {
    return axios.get(REACT_APP_NAVBAR_API);
  };

  return (
    <>
      {user && (
        <AppNavHeaderWithPaths
          fetchPaths={fetchPaths}
          userName={user.profile?.name || null}
          onLogout={handleLogout}
          onLocalPathPush={handleLocalpathPush}
          activePath={activeItem}
        />
      )}
      <main className={styles.Content}>{props.children}</main>
    </>
  );
};

export default Layout;