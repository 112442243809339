import { applyMiddleware, combineReducers, createStore, compose } from "redux";

import thunk from "redux-thunk";

import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { reducer as oidcReducer } from "redux-oidc";

import toolbarReducer from "./reducers/toolbar";
import userReducer from "./reducers/user";
import { reducer as logReducer } from "pages/LogList/redux/logSlice";

import boardReducer from "../../pages/ShipmentBoard/store/reducers/board";

export const history = createBrowserHistory();

export const configureStore = () => {
  const reducers = {
    router: connectRouter(history),
    oidc: oidcReducer,
    board: boardReducer,
    // app internal reducer
    toolbar: toolbarReducer,
    internalUser: userReducer,
    logs: logReducer
  };

  const middleware = [thunk, routerMiddleware(history)];

  const rootReducer = combineReducers({
    ...reducers,
  });

  let composeEnhancers = null;

  if (
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "staging"
  ) {
    composeEnhancers = compose;
  } else {
    composeEnhancers =
      typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;
  }

  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  return createStore(rootReducer, enhancer);
};
