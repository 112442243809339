import axios from '../../../axios/axios-kube';

import * as actionTypes from '../constants/sharedActionTypes';

const getApiVersionStart = () => { return { type: actionTypes.GET_API_VERSION_START }; }
const getApiVersionSuccess = (data) => { return { type: actionTypes.GET_API_VERSION_SUCCESS, apiVersion: data }; }
const getApiVersionError = () => { return { type: actionTypes.GET_API_VERSION_ERROR }; }

export const getApiVersion = () => {
  return dispatch => {
    dispatch(getApiVersionStart());

    axios.get(`/api-version`)
      .then(response => dispatch(getApiVersionSuccess(response.data)))
      .catch(() => dispatch(getApiVersionError()));
  }
}