import * as actionTypes from '../constants/sharedActionTypes';

const saveUserRoleStart = () => { return { type: actionTypes.SAVE_USER_ROLE_START }; }
const saveUserRoleSuccess = (userRole) => { return { type: actionTypes.SAVE_USER_ROLE_SUCCESS, userRole: userRole }; }

export const saveUserRole = (userRole) => {
  return dispatch => {
    dispatch(saveUserRoleStart());
    dispatch(saveUserRoleSuccess(userRole));
  };
}
