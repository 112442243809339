export const {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_API_URL,
  REACT_APP_NAVBAR_API,
  REACT_APP_CENTER_REDIRECT_URL,
} = process.env;

//DriverApi-dev
//DriverApi-staging
//DriverApi
//DriverApiEUStaging
//DriverApiEU
