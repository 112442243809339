import { REACT_APP_AUTH_CLIENT_ID } from "shared/constants/envConstants";

const userRolesUtil = (roles) => {
  let appName = REACT_APP_AUTH_CLIENT_ID;

  let rolesArray = roles.split(",");

  for (let el in rolesArray) {
    if (rolesArray[el].includes(appName)) {
      return rolesArray[el].substring(rolesArray[el].indexOf(":") + 1);
    }
  }
};

export default userRolesUtil;
