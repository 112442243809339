import React from 'react';
import moment from 'moment';

import ResourceViewer from '../../../../components/ResourceViewer';

import { Button } from 'semantic-ui-react';

import { reportTypeFormatter } from '../../../../shared/utils/utility';

import styles from './SignaturesTable.module.scss';

const SignaturesTable = ({
  signatures,
  regenerateInspectionReport }) => {
  const columns = [
    { id: 'signatureType', header: 'Type', sorting: false, accessor: d => reportTypeFormatter(d.signatureType) },
    { id: 'createdDate', header: 'Created Date', sorting: false, accessor: d => moment(d.createdDate).format('MM/DD/YY h:mm A') },
    { id: 'lastName', header: 'Driver Last Name', sorting: false },
    { id: 'action', header: 'Regenerate Report', sorting: false, accessor: d => buildRegenerateReportColumn(d) }
  ];

  const buildRegenerateReportColumn = (item) =>
    <Button basic content='Run' icon='bolt' size='tiny' labelPosition='left' onClick={() => regenerateInspectionReport(item.id)} />;

  return <div className={styles.InfoTable}>
    <ResourceViewer
      columns={columns}
      actions={false}
      editMode={false}
      pagination={false}
      expandable={false}
      pageSizeStatic={true}

      data={signatures}
      totalCount={signatures.length} />
  </div>;
}

export default SignaturesTable;