import React from "react";
import JSONPretty from "react-json-prettify";
import { Table } from "semantic-ui-react";
import styled from "styled-components";
import * as platformType from "shared/constants/logPlatform";

const DetailsRow = (props) => {
  const { type, driverAction, mobileError } = props;
  if (type === platformType.API) {
    return (
      <Table.Row>
        <Table.Cell colSpan="4">
          <ApiDetails driverAction={driverAction} />
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Table.Row>
      <Table.Cell colSpan="4">
        <MobileDetails mobileError={mobileError} />
      </Table.Cell>
    </Table.Row>
  );
};

const ApiDetails = (props) => {
  const { driverAction } = props;
  return (
    <>
      {driverAction?.loading && <div>Loading...</div>}
      {!driverAction?.loading && (
        <ApiDetailsContainerStyled>
          <JSONContainerStyled>
            <b>Request:</b>
            <JSONItemStyled>
              <JSONPretty json={driverAction?.data?.request} theme={theme} />
            </JSONItemStyled>
          </JSONContainerStyled>
          <JSONContainerStyled>
            <b>Response:</b>
            <JSONItemStyled>
              <JSONPretty json={driverAction?.data?.response} theme={theme} />
            </JSONItemStyled>
          </JSONContainerStyled>
        </ApiDetailsContainerStyled>
      )}
    </>
  );
};

const MobileDetails = (props) => {
  const { mobileError } = props;

  const getDataToRender = () => {
    const result = [];

    const getTitle = (element) => {
      const name = element.replace(/([a-z])([A-Z])/g, "$1 $2");
      const title = name[0].toUpperCase() + name.substring(1);
      return title;
    };

    if (!mobileError?.data) return result;
    for (let el in mobileError.data) {
      if (el.toLocaleLowerCase() === "info") continue;
      const title = getTitle(el);
      result.push({ title: title, value: mobileError.data[el] });
    }

    if (!mobileError.data.info) return result;
    for (let el in mobileError.data.info) {
      const title = getTitle(el);
      result.push({ title: title, value: mobileError.data.info[el] });
    }

    return result;
  };

  return (
    <>
      {mobileError?.loading && <div>Loading...</div>}
      {!mobileError?.loading && (
        <MobileDetailsContainer>
          {getDataToRender().map((item) => (
            <MobileDetailsItemContainer>
              <strong>{item.title}:</strong>
              <span style={{ wordBreak: "break-word" }}>
                {item.value || "n/a"}
              </span>
            </MobileDetailsItemContainer>
          ))}
        </MobileDetailsContainer>
      )}
    </>
  );
};

const theme = {
  background: "rgb(248, 248, 248)",
  brace: "rgb(51, 51, 51)",
  keyQuotes: "rgb(51, 51, 51)",
  valueQuotes: "rgb(221, 17, 68)",
  colon: "rgb(51, 51, 51)",
  comma: "rgb(51, 51, 51)",
  key: "rgb(51, 51, 51)",
  value: {
    string: "rgb(221, 17, 68)",
    null: "rgb(0, 128, 128)",
    number: "rgb(0, 128, 128)",
    boolean: "rgb(0, 128, 128)",
  },
  bracket: "rgb(51, 51, 51)",
};

const ApiDetailsContainerStyled = styled.div`
  display: flex;
`;

const JSONContainerStyled = styled.div`
  flex: 1;
  padding: 5px;
  height: 100%;
`;

const JSONItemStyled = styled.div`
  max-height: 500px;
  overflow: auto;
`;

const MobileDetailsContainer = styled.div`
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
`;

const MobileDetailsItemContainer = styled.div`
  margin-right: 50px;
  margin-bottom: 30px;
  width: 200px;
  display: flex;
  flex-direction: column;
`;

export { DetailsRow };
