import React from "react";
import { Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import { MessageCell } from "./MessageCell";
import { PlatformIcon } from "../PlatformIcon";
import { LogTypeLabel } from "../LogTypeLabel";
import { DetailsRow } from "./DetailsRow";
import * as platformType from "shared/constants/logPlatform";

import { actions } from "../../redux/logSlice";

const LogTableRow = (props) => {
  const { item, open, onOpenClick, onCloseClick } = props;
  const dispatch = useDispatch();
  const { driverAction, mobileError } = useSelector((state) => state.logs);

  const getTimeValue = (val) => {
    if (val.includes("Z")) {
      return moment(item.date, true).utc().format("MM/DD/YY h:mm:ss A");
    }

    return moment(item.date, true).format("MM/DD/YY h:mm:ss A");
  };

  const handleOpenClick = () => {
    const { id, groupId, platform } = item;
    if (!open) {
      if (item.platform === platformType.API) {
        dispatch(actions.fetchDriverAction({ id }));
      } else {
        dispatch(
          actions.fetchDriverMobileErrorDetail({
            id,
            groupId,
            platform,
          })
        );
      }

      onOpenClick();
    } else {
      onCloseClick();
    }
  };

  return (
    <>
      <Table.Row {...props}>
        <TableCellStyled>
          <PlatformIcon type={item.platform} /> {item.appVersion || "N/A"}
        </TableCellStyled>
        <TableCellStyled>{`${getTimeValue(item.date)} UTC`}</TableCellStyled>
        <Table.Cell>
          <LogTypeLabel type={item.logType} />
        </Table.Cell>
        <MessageCell
          item={item}
          open={open}
          onOpenClick={handleOpenClick}
          onCloseClick={onCloseClick}
        />
      </Table.Row>

      {open && (
        <DetailsRow
          driverAction={driverAction}
          mobileError={mobileError}
          type={item.platform}
        />
      )}
    </>
  );
};

const TableCellStyled = styled(Table.Cell)`
  white-space: nowrap;
`;

export { LogTableRow };
