import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as LogAPI from "./api";

export const fetchLog = createAsyncThunk(
  "fetchLog",
  async (requestBody, _thunkAPI) => {
    const response = await LogAPI.fetchLog(requestBody);
    return response.data;
  }
);

export const fetchDriverAction = createAsyncThunk(
  "fetchDriverAction",
  async (requestBody, _thinkAPI) => {
    const response = await LogAPI.fetchDriverActions(requestBody);
    return response.data;
  }
);

export const fetchDriverMobileErrorDetail = createAsyncThunk(
  "fetchDriverMobileErrorDetail",
  async (requestBody, _thinkAPI) => {
    const response = await LogAPI.fetchDriverMobileErrorDetail(requestBody);
    return response.data;
  }
);

export const customerSlice = createSlice({
  name: "customerEdit",
  initialState: {
    data: [],
    loading: false,
    driverAction: {
      loading: false,
      data: {},
    },
    mobileError: {
      loading: false,
      data: {}
    }
  },
  reducers: {},
  extraReducers: {
    [fetchLog.pending]: (state, _action) => {
      state.loading = true;
    },
    [fetchLog.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    [fetchLog.rejected]: (state, _action) => {
      state.loading = false;
    },

    [fetchDriverAction.pending]: (state, _action) => {
      state.driverAction.loading = true;
    },
    [fetchDriverAction.fulfilled]: (state, action) => {
      state.driverAction.loading = false;
      state.driverAction.data = action.payload;
    },
    [fetchDriverAction.rejected]: (state, _action) => {
      state.driverAction.loading = false;
    },

    [fetchDriverMobileErrorDetail.pending]: (state, _action) => {
      state.mobileError.loading = true;
    },
    [fetchDriverMobileErrorDetail.fulfilled]: (state, action) => {
      state.mobileError.loading = false;
      state.mobileError.data = action.payload;
    },
    [fetchDriverMobileErrorDetail.rejected]: (state, _action) => {
      state.mobileError.loading = false;
    },
  },
});

export const actions = {
  ...customerSlice.actions,
  fetchLog,
  fetchDriverAction,
  fetchDriverMobileErrorDetail,
};

export const reducer = customerSlice.reducer;
