import * as actionTypes from '../constants/actionTypes';

import { updateObject } from '../../../../shared/utils/utility';

const initialState = {
  shipments: null,
  shipmentsLoading: false,
  inspectionReportRegenerationRes: null,
  inspectionReportRegenerationResLoading: false
}

const fetchShipmentBoardDataStart = (state, action) => updateObject(state, { shipmentsLoading: true });
const fetchShipmentBoardDataSuccess = (state, action) => updateObject(state, { shipmentsLoading: false, shipments: action.shipments });
const fetchShipmentBoardDataError = (state, action) => updateObject(state, { shipmentsLoading: false });


const regenerateInspectionReportStart = (state, action) => updateObject(state, { inspectionReportRegenerationResLoading: true });
const regenerateInspectionReportSuccess = (state, action) => updateObject(state, { inspectionReportRegenerationResLoading: false, inspectionReportRegenerationRes: action.res });
const regenerateInspectionReportError = (state, action) => updateObject(state, { inspectionReportRegenerationResLoading: false });


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SHIPMENT_BOARD_DATA_START: return fetchShipmentBoardDataStart(state, action);
    case actionTypes.FETCH_SHIPMENT_BOARD_DATA_SUCCESS: return fetchShipmentBoardDataSuccess(state, action);
    case actionTypes.FETCH_SHIPMENT_BOARD_DATA_ERROR: return fetchShipmentBoardDataError(state, action);

    case actionTypes.REGENERATE_INSPECTION_REPORT_START: return regenerateInspectionReportStart(state, action);
    case actionTypes.REGENERATE_INSPECTION_REPORT_SUCCESS: return regenerateInspectionReportSuccess(state, action);
    case actionTypes.REGENERATE_INSPECTION_REPORT_ERROR: return regenerateInspectionReportError(state, action);

    default: return state;
  }
}

export default reducer;
