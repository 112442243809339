import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import { Header } from 'semantic-ui-react';

import styles from './withErrorHandle.module.scss';

const withErrorHandler = (WrappedComponent, axios, useRegularToast = true) => {
  class Wrapper extends Component {
    constructor(props) {
      super(props);

      this.state = {
        errorMessage: null,
        error: false
      }

      this.reqInterceptor = axios.interceptors.request.use(req => {
        if (this.props.user && this.props.user.access_token) {
          req.headers.Authorization = `Bearer ${this.props.user.access_token}`;
        }

        this.setState({ error: null });
        return req;
      });
    }

    componentDidMount() {
      this.resInterceptor = axios.interceptors.response.use(res => {
        if (
          res.request.responseType === 'blob' &&
          res.data instanceof Blob &&
          res.data.type &&
          res.data.type.toLowerCase().indexOf('json') !== -1
        ) {
          return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
              res.data = JSON.parse(reader.result);

              if (res.data && res.data.hasOwnProperty('isSuccess') && !res.data.isSuccess) {
                this.setState({ errorMessage: res.data.errorMessage, error: true });
              }

              resolve(Promise.reject(res));
            };

            reader.onerror = () => {
              reject(res);
            };

            reader.readAsText(res.data);
          });
        }

        if (res.data && res.data.hasOwnProperty('isSuccess') && !res.data.isSuccess) {
          this.setState({ errorMessage: res.data.errorMessage, error: true });
        }

        return res;
      }, error => {
        if (!error || !error.response) {
          this.setState({ errorMessage: 'Something went wrong', error: true });
          throw error;
        }

        switch (+error.response.status) {
          case 401:
            this.setState({ errorMessage: 'Not Authorized', error: true });
            break;
          case 403:
            this.setState({ errorMessage: 'Sorry, you don\'t have permission to access this resource', error: true });
            break;
          case 404:
            this.setState({ errorMessage: 'Not Found', error: true });
            break;
          case 504:
            this.setState({ errorMessage: 'Internal Server Error', error: true });
            break;
          case 500:
            this.setState({ errorMessage: error.response.data.errorMessage ? error.response.data.errorMessage : 'Something went wrong', error: true });
            break;
          default:
            this.setState({ errorMessage: 'Something went wrong', error: true });
            break;
        }

        throw error;
      });
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state.error === true && prevState.error !== this.state.error) {
        if (useRegularToast) {
          this.notify();
        }
      }
    }

    componentWillUnmount() {
      axios.interceptors.request.eject(this.reqInterceptor);
      axios.interceptors.response.eject(this.resInterceptor);
    }

    handleClose = () => this.setState({ error: false, errorMessage: null });

    notify = () => {
      toast.error(<>
        <Header as='h3' className={styles.NotificationHeader}>Error</Header>
        <p>{this.state.errorMessage ? this.state.errorMessage : null}</p>
      </>, { autoClose: 10000, onClose: () => this.handleClose() });
    }

    render() {
      let wrappedCmp =
        <>
          <ToastContainer enableMultiContainer newestOnTop
            toastClassName={styles.NotificationToast}
            progressClassName={styles.NotificationProgressBar} />

          <WrappedComponent {...this.props}
            error={!useRegularToast ? this.state.error : null}
            errorMessage={!useRegularToast ? this.state.errorMessage : null} />
        </>;

      return wrappedCmp;
    }
  }

  const mapStateToProps = state => {
    return {
      user: state.oidc.user,
      isLoadingUser: state.oidc.isLoadingUser
    }
  }

  return connect(mapStateToProps, null)(Wrapper);
}

export default withErrorHandler;