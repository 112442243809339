import { Component } from 'react';
import { remove } from '../../shared/utils/utility';

class GridBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pageSize: 10,
      sort: [],
      search: '',
      searchTimer: null
    }

    this.getMasterState = this.getMasterState.bind(this);
  }

  getMasterState() {
    return this.state;
  }

  handlePageSizeChange = (event, pageSize) => {
    if (this.state.pageSize !== pageSize.value) {
      this.setState({ pageSize: pageSize.value, page: 1 }, () => this.loadData());
    }
  }

  handlePageChange = (event, page) => this.setState({ page: page.activePage }, () => this.loadData());

  handleSort = (field) => {
    const { sort } = this.state;

    let updatedSortArray = [...sort];

    const matches = sort.filter(s => s.includes(field));
    if (matches.length > 0) {
      remove(updatedSortArray, matches[0]);

      let direction = matches[0].split(':').pop();

      if (direction === 'asc') {
        updatedSortArray.push(`${field}:${'desc'}`);
      }

      this.setState({ sort: updatedSortArray }, () => { this.loadData() });
    } else {
      updatedSortArray.push(`${field}:asc`);

      this.setState({ sort: updatedSortArray }, () => { this.loadData() });
    }
  }

  handleFilter = (e, { value }) => {
    if (this.state.searchTimer)
      clearTimeout(this.state.searchTimer);

    this.setState({ search: value, page: 1 }, () => {
      if (!value.length) {
        this.loadData();
      } else if (value.startsWith('#') && value.length > 5) {
        this.setState({ searchTimer: setTimeout(() => this.loadData(), 500) });
      } else if (!value.startsWith('#') && value.length > 4) {
        this.setState({ searchTimer: setTimeout(() => this.loadData(), 500) });
      }
    });
  }
}

export default GridBase;