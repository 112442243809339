import axios from "axios/axios-api";

export const fetchLog = ({ phoneNumber, reportDate, shipmentId, customId }) => {
  return axios.post(`admin/report/driver-actions`, {
    phoneNumber,
    reportDate,
    shipmentId,
    customId,
  });
};

export const fetchDriverActions = ({ id }) => {
  return axios.post(`admin/report/driver-action-details/${id}`);
};

export const fetchDriverMobileErrorDetail = ({
  id,
  groupId,
  platform,
}) => {
  return axios.post("admin/report/driver-mobile-error-details", {
    errorId: id,
    groupId: groupId,
    platform: platform,
  });
};