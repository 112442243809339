import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { Icon, Accordion, Header } from 'semantic-ui-react';

import SignatureTable from './SignaturesTable';

import * as actions from '../../../shared/store/actions';

class Signature extends Component {
  state = {
    signatures: null,
    active: false
  }

  componentDidMount() {
    if (this.props.signatures) {
      this.setState({ signatures: this.props.signatures });
    }
  }

  handleClick = () => this.setState({ active: !this.state.active });

  handleRegenerateInspectionReport = (signatureId) => {
    this.props.regenerateInspectionReport(signatureId)
      .then(() => this.notify())
      .catch(() => { });
  }

  notify = () => {
    toast.info(<div>
      <Header as='h3'>Success!</Header>
      <p style={{ color: 'black' }}>Location inspection report document is sent to processing</p>
    </div>, {
      autoClose: 3000,
      pauseOnFocusLoss: false
    });
  }


  render() {
    const { active, signatures } = this.state;

    return <Accordion style={{ width: '100%', paddingRight: '15px' }}>
      <Accordion.Title active={active} onClick={this.handleClick}>
        <Icon name='dropdown' />
        Signatures
    </Accordion.Title>
      <Accordion.Content active={active}>
        {signatures
          ? <SignatureTable
            signatures={signatures}
            regenerateInspectionReport={this.handleRegenerateInspectionReport} />
          : null}
      </Accordion.Content>
    </Accordion>
  }
}

const mapStateToProps = state => {
  return {
    inspectionReportRegenerationRes: state.board.inspectionReportRegenerationRes,
    inspectionReportRegenerationResLoading: state.board.inspectionReportRegenerationResLoading
  };
}

const mapDispatchToProps = dispatch => {
  return {
    regenerateInspectionReport: (signatureId) => dispatch(actions.regenerateInspectionReport(signatureId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signature);